const PROVIDER = {
  shopee: 'shopee',
  yahoo: 'yahoo',
  yahoomall: 'yahoomall',
  rakuten: 'rakuten',
  momomall: 'momomall',
  momocenter: 'momocenter',
};

const PROVIDER_DISPLAY = {
  shopee: '蝦皮購物',
  yahoo: 'Yahoo奇摩購物中心',
  yahoomall: 'Yahoo奇摩超級商城',
  rakuten: '樂天市場',
  momomall: 'momo摩天商城',
  momocenter: 'momo購物網',
};

const PROVIDER_ERP_ORDER_MAP = {
  shopee: 'E05', // E05: '蝦皮',
  yahoo: 'E02', //  E02: '雅虎超級商城',
  yahoomall: 'E06', // E06: '雅虎購物中心',
  rakuten: 'E04', //  E04: '樂天商城',
  momomall: 'E03', // E03: 'MOMO摩天商城',
  momocenter: 'E07', // E07: 'MOMO 購物中心',
};

const PROVIDER_TYPE = {
  mall: 'mall',
  center: 'center',
};

const PROVIDERS_BY_TYPE = {
  mall: [
    PROVIDER.shopee,
    PROVIDER.yahoomall,
    PROVIDER.rakuten,
    PROVIDER.momomall,
  ],
  center: [PROVIDER.yahoo, PROVIDER.momocenter],
};

const PROVIDER_ID_TYPES = {
  shopee: 'number',
  yahoo: 'number',
  yahoomall: 'string',
  rakuten: 'string',
  momomall: 'string',
  momocenter: 'string',
};

const INTERNAL_ORDER_STATUS = {
  pending_pending: 'pending_pending',
  'pending_out-of-stock': 'pending_out-of-stock',
  'pending_in-queue': 'pending_in-queue',
  pending_contacting: 'pending_contacting',
  'pending_wait-uploading': 'pending_wait-uploading',
  pending_refund: 'pending_refund',
  pending_exchange: 'pending_exchange',
  pending_cancel: 'pending_cancel',
  done_done: 'done_done',
  done_refund: 'done_refund',
  done_exchange: 'done_exchange',
  done_cancel: 'done_cancel',
};

const INTERNAL_ORDER_STATUS_DISPLAY = {
  pending_pending: '待出貨',
  'pending_out-of-stock': '缺貨中',
  'pending_in-queue': '排單等貨',
  pending_contacting: '待聯絡',
  'pending_wait-uploading': '待上傳',
  pending_refund: '申請退貨',
  pending_exchange: '申請換貨',
  pending_cancel: '待取消',
  done_done: '已出貨',
  done_refund: '已退貨',
  done_exchange: '已換貨',
  done_cancel: '已取消',
};

const MAIN_INTERNAL_ORDER_STATUS = {
  pending: 'pending',
  done: 'done',
};

const MAIN_INTERNAL_ORDER_STATUS_DISPLAY = {
  pending: '待處理',
  done: '已完成',
};

export {
  PROVIDER,
  PROVIDER_DISPLAY,
  PROVIDER_ERP_ORDER_MAP,
  PROVIDER_TYPE,
  PROVIDERS_BY_TYPE,
  PROVIDER_ID_TYPES,
  INTERNAL_ORDER_STATUS,
  INTERNAL_ORDER_STATUS_DISPLAY,
  MAIN_INTERNAL_ORDER_STATUS,
  MAIN_INTERNAL_ORDER_STATUS_DISPLAY,
};
