import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import {Button, message, Input} from 'antd';
import {PROVIDER, PROVIDER_DISPLAY} from '../../Constants';
import {
  PageTitle,
  SectionTitle,
  FieldRow,
  FieldLabel,
  FieldValue,
  SeperatorLine,
} from '../../Components/Widgets';

function SiteConfigPage(props) {
  const [actions] = useOutlet('actions');
  const [siteConfig, setSiteConfig] = useOutlet('site-config');

  const getPlatformInputProps = (
    parentFieldKey,
    index,
    fieldKey,
    {type} = {},
  ) => {
    return {
      disabled: false,
      value: siteConfig[parentFieldKey][index][fieldKey],
      type,
      onChange: (evt) => {
        let nextValue = evt.target.value;
        if (
          typeof nextValue === 'string' &&
          type === 'number' &&
          fieldKey === 'value'
        ) {
          nextValue = parseFloat(nextValue);
          if (isNaN(nextValue)) {
            nextValue = 0;
          }
        }
        setSiteConfig((siteConfig) => {
          let values = [...siteConfig[parentFieldKey]];
          values[index][fieldKey] = nextValue;
          return {
            ...siteConfig,
            [parentFieldKey]: values,
          };
        });
      },
    };
  };

  const onSaveSiteConfig = async () => {
    let appendDefaultData = {};
    try {
      for (let key in PROVIDER) {
        let arr = siteConfig[`${key}_fees`];
        if (!arr) {
          appendDefaultData = [];
          continue;
        }
        for (let ele of arr) {
          if (!ele.title || (ele.value !== 0 && !ele.value)) {
            throw '請確認所有欄位都已填寫！';
          }
        }
      }
    } catch (err) {
      message.error(err);
      return;
    }

    try {
      actions.setLoading(true);
      let resp = await actions.updateDocument(
        'platform_config',
        {
          id: siteConfig.id,
        },
        {
          ...siteConfig,
          ...appendDefaultData,
        },
      );
      setSiteConfig(resp);
      message.success('已成功儲存');
    } catch (err) {
      message.error('無法儲存');
    } finally {
      actions.setLoading(false);
    }
  };

  const onPlatformCostAdd = function (providerKey) {
    setSiteConfig((siteConfig) => {
      let nextValues = [
        ...(siteConfig[providerKey] || []),
        {title: '', value: 0},
      ];
      return {
        ...siteConfig,
        [providerKey]: nextValues,
      };
    });
  };

  const onPlatformCostRemove = function (providerKey, idx) {
    setSiteConfig((siteConfig) => {
      let arr = siteConfig[providerKey] || [];
      let nextValues = [...arr.slice(0, idx), ...arr.slice(idx + 1)];
      return {
        ...siteConfig,
        [providerKey]: nextValues,
      };
    });
  };

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
        <PageTitle>平台後扣手續費</PageTitle>

        <Button style={{marginLeft: 10}} onClick={onSaveSiteConfig}>
          儲存
        </Button>
      </div>

      {Object.keys(PROVIDER).map((key, idx) => (
        <ProviderWrapper key={idx}>
          <SectionTitle style={{marginLeft: 10}}>
            合作通路： {PROVIDER_DISPLAY[key]}
          </SectionTitle>
          <SeperatorLine />
          <div className="fees-wrapper">
            <Header>
              <div className="col" style={{marginRight: 5, width: 320}}>
                收費項目
              </div>
              <div className="col" style={{marginRight: 5, width: 120}}>
                收費比例
              </div>
              <div className="col" style={{marginRight: 5, width: 70}}>
                動作
              </div>
            </Header>

            {(siteConfig[`${key}_fees`] || []).map((d, idx) => (
              <Field key={idx}>
                <Input
                  {...getPlatformInputProps(`${key}_fees`, idx, 'title')}
                  style={{marginRight: 5, width: 320}}
                />
                <Input
                  addonAfter="%"
                  {...getPlatformInputProps(`${key}_fees`, idx, 'value')}
                  style={{marginRight: 5, width: 120}}
                />
                <Button
                  onClick={() => onPlatformCostRemove(`${key}_fees`, idx)}>
                  移除
                </Button>
              </Field>
            ))}
            <Button onClick={() => onPlatformCostAdd(`${key}_fees`)}>
              新增
            </Button>
          </div>
        </ProviderWrapper>
      ))}
    </Wrapper>
  );
}

const Header = styled.div`
  display: flex;
  background-color: #eee;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  & > .col {
    font-size: 14px;
  }
`;

const ProviderWrapper = styled.div`
  margin-top: 5px;
  border: 1px solid #cecece;
  border-radius: 4px;
  padding: 10px 0px;

  & .fees-wrapper {
    padding: 0px 20px;
    padding-top: 10px;
  }
`;

const Wrapper = styled.div`
  padding: 20px;
`;
const Field = styled.div`
  margin-bottom: 10px;

  & label {
    margin-right: 5px;
  }
`;

export default SiteConfigPage;
